import { Button, PageContainer, PageHeading, SelectList, SelectListOption } from "./Wrappers"
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChapterDetails, useQuran } from "../Services/QuranService";
import { useEffect } from "react";
import useLocalStorage from "use-local-storage";
import { Checkbox } from "./Wrappers/Checkbox";


export const QuranVerse = () => {
    const { chapter, verse } = useParams();
    const { quranTools, load } = useQuran();

    const currentChapterNumber = () => {
        return chapter ?? "1";
    }
    const currentVerseNumber = () => {
        return verse ?? "1";
    }
    const [linksOnArabicWords, setLinksOnArabicWords] = useLocalStorage("ShariahStandards-QuranVerse-linkedArablcWords", false);
    const [showMoreThanOneVerse, setShowMoreThanOneVerse] = useLocalStorage("ShariahStandards-QuranVerse-showMoreThanOneVerse", false);
    const navigate = useNavigate();
    const navigateToMatchingRoute = (surah: string, verse: string) => {
        navigate("/QuranVerse/" + surah + "/" + verse)
    }
    const showNextVerses=()=>{
        if(quranTools&& selectedChapterDetails){
            var nextVerse=Number(currentVerseNumber())+7;
            if(showMoreThanOneVerse){
                nextVerse=Math.min(nextVerse,selectedChapterDetails.total_verses);
             
                navigateToMatchingRoute(currentChapterNumber(),nextVerse.toString());
            }else{
                nextVerse=Number(currentVerseNumber())+1;
                nextVerse=Math.min(nextVerse,selectedChapterDetails.total_verses);
             
                navigateToMatchingRoute(currentChapterNumber(),nextVerse.toString());
            }
        }
    }
    const showNextSurah=()=>{
        if(quranTools&& selectedChapterDetails){
            var nextSurah=Number(currentChapterNumber())+1;
            nextSurah=Math.min(nextSurah,114);
            navigateToMatchingRoute(nextSurah.toString(),"1");

        }
    }
    let selectedChapterDetails: ChapterDetails | undefined = undefined;
    if (quranTools) {
        var chapterIndex = Number(currentChapterNumber()) - 1;
        selectedChapterDetails = quranTools.chapterDetails[chapterIndex];
    }
    let verseOptions: SelectListOption[] = []
    if (selectedChapterDetails) {
        verseOptions = Array.from(Array(selectedChapterDetails.total_verses).keys())
            .map(index => { return index + 1; }).map(num => { return { content: num.toString(), keyValue: num.toString() } })
    }
    useEffect(() => {
        if (!quranTools) {
            load()
        }
    }, [load, quranTools]);
    let verseNumbersToShow = ()=>{
        if(quranTools===undefined || (!showMoreThanOneVerse) || selectedChapterDetails===undefined){
            return [currentVerseNumber()];
        }
        var minVerseNumber=Math.max(1,Number(currentVerseNumber())-3);
        var maxVerseNumber=Math.min(minVerseNumber+6,selectedChapterDetails.total_verses);
        let verseNumbers:string[]=[];
        for(var v=minVerseNumber;v<=maxVerseNumber;v++){
            verseNumbers.push(v.toString())
        }
        return verseNumbers;
    };

    const shownVerses=verseNumbersToShow();
    return (
        <PageContainer>
            <PageHeading>Qur'an Verse Browser</PageHeading>

            {quranTools
                &&
                <div>
                    Chapter (Surah)
                    <SelectList options={quranTools.chapterDetails.map(c => {
                        return {
                            content: c.id + " " + c.translation + " (" + c.name + " " + c.transliteration + ")",
                            keyValue: c.id.toString()
                        }
                    })}
                        selectedOptionKeyValue={currentChapterNumber()}
                        onChange={val => navigateToMatchingRoute(val, "1")}
                    />
                    Verse (Ayah)
                    <SelectList
                        options={verseOptions}
                        selectedOptionKeyValue={verse ?? "1"}
                        onChange={val => navigateToMatchingRoute(chapter ?? "1", val)}
                    />
                </div>}

            <Checkbox OnChecked={() => setLinksOnArabicWords(true)} OnUnchecked={() => setLinksOnArabicWords(false)} value={linksOnArabicWords}>Add search links for arabic words</Checkbox>
            <Checkbox OnChecked={() => setShowMoreThanOneVerse(true)} OnUnchecked={() => setShowMoreThanOneVerse(false)} value={showMoreThanOneVerse}>Show more than one verse</Checkbox>
            {quranTools === undefined && <div>loading Qur'an...</div>}
            {quranTools && 
            
            <div className="flex flex-col">
            {shownVerses.map(verseNumber=>
                <QuranVerseView
                linksOnArabicWords={linksOnArabicWords} 
                key={currentChapterNumber() + ":" + verseNumber}
                arabicVerse={quranTools.arabicQuran[currentChapterNumber()][Number(verseNumber) - 1].text}
                englishVerse={quranTools.englishQuran[currentChapterNumber()][Number(verseNumber) - 1].text}
                verseNumber={verseNumber}
                highlight={currentVerseNumber()===verseNumber && showMoreThanOneVerse} />)}
            </div>
            

            }
            <div className="flex flex-row items-center w-1/2">
                {selectedChapterDetails && quranTools && (shownVerses[shownVerses.length-1]!==selectedChapterDetails.total_verses.toString()) 
                && <div className="w-1/2 text-center"><Button onClick={showNextVerses}>Next Verse{showMoreThanOneVerse&&"s"}</Button></div>}
                {" "} {selectedChapterDetails && quranTools && (Number(currentChapterNumber())<114) 
                && <div className="w-1/2 text-center"><Button onClick={showNextSurah}>Next Surah</Button></div>}
            </div>
        </PageContainer>
    )
}
export const QuranVerseView = (props: { linksOnArabicWords: boolean, arabicVerse: string, englishVerse: string, verseNumber: string, highlight:boolean}) => {
    return (<div className={"flex md-flex-col lg:flex-row "+(props.highlight?"bg-red-200 shadow-md":"")}>

        <div className="m-2 text-xl w-1/2 p-2 border-2 shadow-md rounded-sm bg-green-50 border-slate-300">
            <span>({props.verseNumber}){" "}</span>
            {!props.linksOnArabicWords && props.arabicVerse}
            {props.linksOnArabicWords && props.arabicVerse.split(' ').map((word,wordIndex) =>
                <Link key={props.verseNumber+wordIndex} to={"/QuranSearch/Arabic/" + encodeURI(word)}>{word} {" "}</Link>)}
        </div>
        <div className="m-2 text-xl w-1/2 p-2 border-2 shadow-md rounded-sm bg-yellow-50 border-slate-300">
            <span>({props.verseNumber}){" "}</span>
            {props.englishVerse}
        </div>
    </div>)
}